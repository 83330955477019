/**
 * Running a local relay server will allow you to hide your API key
 * and run custom logic on the server
 *
 * Set the local relay server address to:
 * REACT_APP_LOCAL_RELAY_SERVER_URL=http://localhost:8081
 *
 * This will also require you to set OPENAI_API_KEY= in a `.env` file
 * You can run it with `npm run relay`, in parallel with `npm start`
 */
const LOCAL_RELAY_SERVER_URL: string =
  process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

// Add this line to read the API key from the environment variable
const OPENAI_API_KEY: string = process.env.REACT_APP_OPENAI_API_KEY || '';

import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Select,
  Button,
  Flex,
  Heading,
  useDisclosure,
  Collapse,
  Container,
  Image,
} from '@chakra-ui/react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { getInstructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';
import { X, Edit, Zap, ArrowUp, ArrowDown, ChevronUp, ChevronDown, Globe } from 'react-feather';

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

export function ConsolePage() {
  /**
   * Use the API key from the environment variable
   * If we're using the local relay server, we don't need this
   */
  const apiKey = LOCAL_RELAY_SERVER_URL ? '' : OPENAI_API_KEY;

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { url: LOCAL_RELAY_SERVER_URL }
        : {
            apiKey: apiKey,
            dangerouslyAllowAPIKeyInBrowser: true,
          }
    )
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});

  const [sourceLang, setSourceLang] = useState('English');
  const [targetLang, setTargetLang] = useState('Russian');

  const { isOpen: isEventsPaneOpen, onToggle: toggleEventsPane } = useDisclosure({ defaultIsOpen: true });

  const [turnEndType, setTurnEndType] = useState<string>('none');

  /**
   * Utility for formatting the timing of logs
   */
  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  /**
   * When you click the API key
   */
  const resetAPIKey = useCallback(() => {
    const apiKey = prompt('OpenAI API Key');
    if (apiKey !== null) {
      localStorage.clear();
      localStorage.setItem('tmp::voice_api_key', apiKey);
      window.location.reload();
    }
  }, []);

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    
    if (client.isConnected()) {
      console.log('Already connected');
      return;
    }

    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    try {
      // Connect to microphone
      await wavRecorder.begin();

      // Connect to audio output
      await wavStreamPlayer.connect();

      // Connect to realtime API
      await client.connect();

      if (client.getTurnDetectionType() === 'server_vad') {
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
      }
    } catch (error) {
      console.error('Error connecting:', error);
      setIsConnected(false);
      // Handle the error appropriately (e.g., show an error message to the user)
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setMemoryKv({});

    const client = clientRef.current;
    if (client.isConnected()) {
      await client.disconnect();
    }

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === 'none');
    setTurnEndType(value);
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#009900',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  const updateInstructions = useCallback(() => {
    const client = clientRef.current;
    const newInstructions = getInstructions(sourceLang, targetLang);
    client.updateSession({ instructions: newInstructions });
  }, [sourceLang, targetLang]);

  useEffect(() => {
    updateInstructions();
  }, [sourceLang, targetLang, updateInstructions]);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

    // Add tools
    client.addTool(
      {
        name: 'set_memory',
        description: 'Saves important data about the user into memory.',
        parameters: {
          type: 'object',
          properties: {
            key: {
              type: 'string',
              description:
                'The key of the memory value. Always use lowercase and underscores, no other characters.',
            },
            value: {
              type: 'string',
              description: 'Value can be anything represented as a string',
            },
          },
          required: ['key', 'value'],
        },
      },
      async ({ key, value }: { [key: string]: any }) => {
        setMemoryKv((memoryKv) => {
          const newKv = { ...memoryKv };
          newKv[key] = value;
          return newKv;
        });
        return { ok: true };
      }
    );

    // handle realtime events from client + server for event logging
    client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // if we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);

  /**
   * Render the application
   */
  return (
    <Box height="100vh" display="flex" flexDirection="column" overflow="hidden">
      <Box bg="white" boxShadow="sm" py={4}>
        <Container maxW="container.xl">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap={4}>
              <Box>
                <Heading as="h1" size="lg">realtime translator</Heading>
                <Text fontSize="sm" color="gray.600">Speak in one language, hear in another - real-time voice translation at your fingertips.</Text>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>

      <Box flex="1" overflow="hidden" display="flex" flexDirection="column">
        <Container maxW="container.xl" py={6} height="100%" display="flex" flexDirection="column">
          <Box bg="white" borderRadius="md" boxShadow="sm" p={4} mb={4}>
            <Text fontWeight="medium" mb={3}>Select your languages:</Text>
            <Flex justifyContent="center" alignItems="center" gap={4}>
              <Select value={sourceLang} onChange={(e) => setSourceLang(e.target.value)}>
                <option value="English">English</option>
                <option value="Russian">Russian</option>
                <option value="Spanish">Spanish</option>
                <option value="French">French</option>
                <option value="German">German</option>
                <option value="Italian">Italian</option>
                <option value="Portuguese">Portuguese</option>
                <option value="Chinese">Chinese</option>
                <option value="Japanese">Japanese</option>
                <option value="Korean">Korean</option>
              </Select>
              <ArrowUp size={16} />
              <Select value={targetLang} onChange={(e) => setTargetLang(e.target.value)}>
                <option value="English">English</option>
                <option value="Russian">Russian</option>
                <option value="Spanish">Spanish</option>
                <option value="French">French</option>
                <option value="German">German</option>
                <option value="Italian">Italian</option>
                <option value="Portuguese">Portuguese</option>
                <option value="Chinese">Chinese</option>
                <option value="Japanese">Japanese</option>
                <option value="Korean">Korean</option>
              </Select>
              <Globe size={16} />
            </Flex>
          </Box>

          <Box flex="1" overflow="hidden" display="flex" flexDirection="column">
            <Box bg="white" borderRadius="md" boxShadow="sm" p={4} mb={4} flex="1" overflow="hidden" display="flex" flexDirection="column">
              <Heading as="h3" size="md" mb={4}>conversation</Heading>
              <Box flex="1" overflowY="auto" data-conversation-content>
                {!items.length && <Text>awaiting connection...</Text>}
                {items.map((conversationItem, i) => (
                  <Flex key={conversationItem.id} mb={4} alignItems="flex-start">
                    <Box width="100px" flexShrink={0} mr={4}>
                      <Text color={conversationItem.role === 'user' ? 'blue.500' : 'green.500'}>
                        {conversationItem.role === "assistant" ? "translator" : conversationItem.role || conversationItem.type}
                      </Text>
                    </Box>
                    <Box flexGrow={1}>
                      {conversationItem.type === 'function_call_output' && (
                        <Text>{conversationItem.formatted.output}</Text>
                      )}
                      {!!conversationItem.formatted.tool && (
                        <Text>
                          {conversationItem.formatted.tool.name}(
                          {conversationItem.formatted.tool.arguments})
                        </Text>
                      )}
                      {!conversationItem.formatted.tool && conversationItem.role === 'user' && (
                        <Text>
                          {conversationItem.formatted.transcript ||
                            (conversationItem.formatted.audio?.length
                              ? '(awaiting transcript)'
                              : conversationItem.formatted.text ||
                                '(item sent)')}
                        </Text>
                      )}
                      {!conversationItem.formatted.tool && conversationItem.role === 'assistant' && (
                        <Text>
                          {conversationItem.formatted.transcript ||
                            conversationItem.formatted.text ||
                            '(truncated)'}
                        </Text>
                      )}
                      {conversationItem.formatted.file && (
                        <audio
                          src={conversationItem.formatted.file.url}
                          controls
                        />
                      )}
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Box>

            <Flex direction={{ base: "column", md: "row" }} gap={4} justify="space-between" align="center" mb={4}>
              <HStack>
                <Button
                  onClick={() => changeTurnEndType('none')}
                  variant={turnEndType === 'none' ? 'solid' : 'outline'}
                >
                  manual
                </Button>
                <Button
                  onClick={() => changeTurnEndType('server_vad')}
                  variant={turnEndType === 'server_vad' ? 'solid' : 'outline'}
                >
                  vad
                </Button>
              </HStack>
              {isConnected && canPushToTalk && (
                <Button
                  onMouseDown={startRecording}
                  onMouseUp={stopRecording}
                  onTouchStart={startRecording}
                  onTouchEnd={stopRecording}
                  colorScheme={isRecording ? 'red' : 'blue'}
                  isDisabled={!isConnected || !canPushToTalk}
                >
                  {isRecording ? 'release to send' : 'push to talk'}
                </Button>
              )}
              <Button
                onClick={isConnected ? disconnectConversation : connectConversation}
                leftIcon={isConnected ? <X /> : <Zap />}
                colorScheme={isConnected ? 'gray' : 'green'}
              >
                {isConnected ? 'disconnect' : 'connect'}
              </Button>
            </Flex>

            <Box>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                p={2}
                bg="gray.100"
                cursor="pointer"
                onClick={toggleEventsPane}
              >
                <Text fontWeight="medium">events</Text>
                <Button size="sm" variant="ghost" rightIcon={isEventsPaneOpen ? <ChevronDown /> : <ChevronUp />}>
                  {isEventsPaneOpen ? 'hide' : 'show'}
                </Button>
              </Flex>
              <Collapse in={isEventsPaneOpen}>
                <Box bg="white" height="200px" overflowY="auto" ref={eventsScrollRef}>
                  {!realtimeEvents.length && <Text p={4}>awaiting connection...</Text>}
                  {realtimeEvents.map((realtimeEvent, i) => {
                    const count = realtimeEvent.count;
                    const event = { ...realtimeEvent.event };
                    if (event.type === 'input_audio_buffer.append') {
                      event.audio = `[trimmed: ${event.audio.length} bytes]`;
                    } else if (event.type === 'response.audio.delta') {
                      event.delta = `[trimmed: ${event.delta.length} bytes]`;
                    }
                    return (
                      <Flex key={event.event_id} mb={2} alignItems="flex-start">
                        <Text width="80px" flexShrink={0} mr={4}>
                          {formatTime(realtimeEvent.time)}
                        </Text>
                        <Box flexGrow={1}>
                          <Flex
                            alignItems="center"
                            cursor="pointer"
                            onClick={() => {
                              const expanded = { ...expandedEvents };
                              expanded[event.event_id] = !expanded[event.event_id];
                              setExpandedEvents(expanded);
                            }}
                          >
                            <Box
                              color={event.type === 'error'
                                ? 'red.500'
                                : realtimeEvent.source === 'client'
                                ? 'blue.500'
                                : 'green.500'}
                              mr={2}
                            >
                              {realtimeEvent.source === 'client' ? <ArrowUp size={12} /> : <ArrowDown size={12} />}
                            </Box>
                            <Text fontWeight="medium">{event.type}{count && ` (${count})`}</Text>
                          </Flex>
                          {!!expandedEvents[event.event_id] && (
                            <Box mt={2} pl={4} fontSize="sm" whiteSpace="pre-wrap">
                              {JSON.stringify(event, null, 2)}
                            </Box>
                          )}
                        </Box>
                      </Flex>
                    );
                  })}
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
