import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { ConsolePage } from './pages/ConsolePage';

function App() {
  return (
    <ChakraProvider>
      <Box data-component="App" minHeight="100vh" fontFamily="'Roboto Mono', monospace">
        <ConsolePage />
      </Box>
    </ChakraProvider>
  );
}

export default App;
